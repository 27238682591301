import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/blogLayout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Button from "../components/button"
import Img from "gatsby-image"

import '../styles/blog.css'

class Books extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    const booksPath = `${__PATH_PREFIX__}/books`

    return (
      <Layout location={this.props.location} title={siteTitle} maxWidth={1040}>
        <SEO title="All book notes" />
        <div class="book-list-header">
          <h1 class="blog-post-title" style={{fontSize: "32px"}}>📚 My Reading List and Book Notes</h1>
          <p>
            Welcome to my personal reading list. It consists of books I read as well as summaries and digests of these books and notes on their insights.
          </p>
        </div>
        <div class="book-list">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <Link to={`${booksPath}${node.fields.slug}`} className="book-note-card-link-wrapper" >
                <div class="book-note-card" key={node.fields.slug}>
                  <div class="book-cover">
                    <Img fixed={node.frontmatter.cover.childImageSharp.fixed} />
                  </div>
                  <div class="book-details">
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      {title}
                    </h3>                
                    <div class="book-author">{node.frontmatter.author}</div>
                    <div class="book-impression">{node.frontmatter.impression}</div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        <Link to="/">
          <Button marginTop="85px">Back to Homepage</Button>
        </Link>
      </Layout>
    )
  }
}

export default Books

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { 
        posttype: { eq: "book-note" }
        status: { eq: "published" }
      } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            impression
            cover {
              childImageSharp {
                fixed (width: 140) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
